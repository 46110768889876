<template>
  <template v-for="item in listdata" :key="item.id">
    <div style="margin:10px;">
        <div @click="notice(item.id)" style="text-decoration:underline;cursor:pointer">{{item.title}}</div>
        <div style="margin-top:5px">{{item.createTime}}</div>
    </div>
    <div style="height:2px;background-color: #eee;"></div>
  </template>
</template>

<script>
    export default {
      data() {
        return {
          listdata: ''
        }
      },
      mounted: function () {
        this.$net.noticeList().then(({ data }) => {
          this.listdata=data.list
        });
      },
      methods:{
        notice(id){
          this.$router.push({path:'/notice/'+id})
        }
      }
    }
  </script>